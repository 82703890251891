import {
	BarChart,
	Bar,
	CartesianGrid,
	YAxis,
	XAxis,
	Tooltip,
	ResponsiveContainer,
	LabelList
} from 'recharts';

// Our Components
import ChartToolTip from 'components/Chart/ChartToolTip';

// Our Util
import { dollarFormatter } from 'shared/utils';

function getChartConfig(chartData) {
	const hasAllPositiveValues = chartData.every(
		(currentBar) => currentBar.value > 0
	);
	const hasAllNegativeValues = chartData.every(
		(currentBar) => currentBar.value < 0
	);
	const hasSomeNegativeValues = chartData.some(
		(currentBar) => currentBar.value < 0
	);

	const minValue = Math.min(
		...chartData.map((currentBar) => currentBar.value)
	);

	const maxValue = Math.max(
		...chartData.map((currentBar) => currentBar.value)
	);

	// default domain of the chart
	const domain = [0, 'auto'];

	const defaultConfig = {
		labelListPosition: 'right',
		domain
	};

	if (hasAllPositiveValues)
		return {
			...defaultConfig,
			domain: [0, `dataMax + ${maxValue * 0.08}`]
		};

	if (hasAllNegativeValues)
		return {
			...defaultConfig,
			domain: [`dataMin - ${-minValue * 0.08}`, 0]
		};

	if (hasSomeNegativeValues) {
		return {
			...defaultConfig,
			labelListPosition: 'left'
		};
	}

	return defaultConfig;
}

function DynamicBarChart({ data }) {
	const { labelListPosition, domain } = getChartConfig(data);

	return (
		<ResponsiveContainer width="100%" height="100%">
			<BarChart
				barSize={40}
				barGap={20}
				layout="vertical"
				width="100%"
				height="100%"
				data={data}
			>
				<CartesianGrid horizontal={false} />
				<YAxis
					type="category"
					dataKey="name"
					width={140}
					tickLine={false}
					tick={{
						fill: 'black'
					}}
					tickMargin={10}
					stroke="#e6e6e6"
				/>
				<XAxis
					domain={domain}
					type="number"
					tickFormatter={(xValue) => {
						const formattedValue = dollarFormatter.format(xValue);
						return formattedValue;
					}}
					tick={{
						fill: 'black'
					}}
					tickLine={false}
					tickMargin={10}
					stroke="#e6e6e6"
				/>

				<Tooltip
					content={ChartToolTip}
					cursor={{ fill: 'transparent' }}
				/>

				<Bar dataKey="value" fill={data.fill}>
					<LabelList
						dataKey="value"
						position={labelListPosition}
						style={{
							fill: '#585858'
						}}
						formatter={(v) => {
							const formattedValue = dollarFormatter.format(v);
							return formattedValue;
						}}
					/>
				</Bar>
			</BarChart>
		</ResponsiveContainer>
	);
}

export default DynamicBarChart;
