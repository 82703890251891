import { Grid } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

// Our Components
import AnalyticsIntroCard from 'components/Analytics/Intro/AnalyticsIntroCard';
import PageHeading from 'components/PageHeading/PageHeading';

// Assets
import BarGraphImage from 'assets/icons/analytics_icons/bar_graph_icon.svg';
import BillIcon from 'assets/icons/analytics_icons/bill_icon.svg';
import DocumentIcon from 'assets/icons/analytics_icons/document_icon.svg';

// Local Constants
const ANALYTICS_CARDS_CONFIG = [
	{
		background: 'linear-gradient(180deg, #ABD2E8 0%, #F5F9FC 100%)',
		descriptionText: "Learn how to best deploy your client's net income.",
		disabled: false,
		textColor: 'black',
		headingImage: BarGraphImage,
		headingText: 'Marginal Dollar Analyzer'
	},
	{
		background:
			'linear-gradient(180deg, #295983 0%, rgba(41, 89, 131, 0.42) 100%)',
		descriptionText: "Compare different loans for your client's mortgage.",
		disabled: false,
		textColor: 'white',
		headingImage: DocumentIcon,
		headingText: 'Loan Comparison Tool'
	},
	{
		background: 'linear-gradient(180deg, #CBE7F0 0%, #EEFCFF 100%)',
		disabled: false,
		descriptionText:
			'Discover the cheapest way for your client to access liquidity.',
		textColor: 'black',
		headingImage: BillIcon,
		headingText: 'Liquidity Analyzer'
	}
];

function AnalyticsIntroPage() {
	return (
		<>
			<PageHeading
				title="Analytics tools for smarter financial decisions"
				subtitleText="Explore our suite of analytics tools to help your clients make the best financial decisions for their future."
			/>

			<Grid
				container
				item
				xs={12}
				sx={{
					height: 600,
					justifyContent: 'space-between',
					textAlign: 'center'
				}}
			>
				{ANALYTICS_CARDS_CONFIG.map((card, index) => {
					const {
						background,
						descriptionText,
						disabled,
						headingText,
						headingImage,
						textColor
					} = card;

					const key = uuidv4();

					return (
						<AnalyticsIntroCard
							key={key}
							background={background}
							descriptionText={descriptionText}
							disabled={disabled}
							headingText={headingText}
							headingImage={headingImage}
							textColor={textColor}
							tabIndex={index}
						/>
					);
				})}
			</Grid>
		</>
	);
}

export default AnalyticsIntroPage;
