import { useMutation, useQueryClient } from 'react-query';

// Our Axios Instance
import analyticsAxiosInstance from 'services/API/AnalyticsAPI';

// Our Endpoints
import { liquidityEndpoint } from 'shared/analyticsEndpoints';

// Our Query keys
import { ERROR_MESSAGE_DATA } from 'shared/query-keys';

function useMutateGetLiquidAnalysis() {
	const queryClient = useQueryClient();

	return useMutation(
		async (assumptionsPayload) => {
			const response = await analyticsAxiosInstance.post(
				liquidityEndpoint,
				assumptionsPayload
			);

			return response.data.data;
		},
		{
			onError: (error) => {
				const errorMessage = error.response.data.data;
				queryClient.setQueryData(ERROR_MESSAGE_DATA, errorMessage);
			}
		}
	);
}

export default useMutateGetLiquidAnalysis;
