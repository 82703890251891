import { useMutation } from 'react-query';
import axios from 'axios';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our Endpoints
import { userGetLoginTokenUrl } from 'shared/api-urls';

const onboardingServiceUrl = process.env.REACT_APP_ONBOARDING_SERVICE_URL;

function useMutateStartOnboardingProcess() {
	return useMutation(async (onboardClientDetails) => {
		const userTokenResponse = await axiosInstance.get(userGetLoginTokenUrl);

		const userTokenData = userTokenResponse.data.data;

		const onboardingRequestPayload = {
			...onboardClientDetails,
			...userTokenData
		};

		const response = await axios.post(
			`${onboardingServiceUrl}/onboard/single/client`,
			onboardingRequestPayload,
			{
				headers: {
					'X-API-Key': process.env.REACT_APP_ONBOARDING_API_KEY
				}
			}
		);

		return response.data;
	});
}

export default useMutateStartOnboardingProcess;
