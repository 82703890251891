import { Box, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { v4 as uuidv4 } from 'uuid';

// Local Constants
const LIQUIDITY_PILL_OPTIONS = [
	'Sell Assets',
	'Interest Only Liabilities',
	'Amortized Liabilities'
];

function LiquidityOptions({
	optionTextSx,
	selectedLiquidityOptions,
	setSelectedLiquidityOptions
}) {
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	return (
		<Box sx={{ display: 'flex', gap: 2, marginTop: 1 }}>
			{LIQUIDITY_PILL_OPTIONS.map((option) => {
				const isSelected = selectedLiquidityOptions
					.map(({ type }) => type)
					.includes(option);

				return (
					<Box
						key={uuidv4()}
						sx={{
							border: '1px solid black',
							padding: 2,
							paddingTop: 1,
							paddingBottom: 1,
							borderRadius: 2,
							'&:hover': {
								// cursor: 'pointer'
							},
							backgroundColor: isSelected
								? primary.indigo
								: 'white',
							color: isSelected ? 'white' : 'black',
							userSelect: 'none'
						}}
						// onClick={() => {
						// 	if (isSelected) {
						// 		const filteredSelectedLiquidityOptions =
						// 			selectedLiquidityOptions.filter(
						// 				({ type }) => type !== option
						// 			);

						// 		setSelectedLiquidityOptions(
						// 			filteredSelectedLiquidityOptions
						// 		);
						// 		return;
						// 	}
						// 	setSelectedLiquidityOptions([
						// 		...selectedLiquidityOptions,
						// 		{
						// 			type: option,
						// 			interestRate: '',
						// 			loanTerm: ''
						// 		}
						// 	]);
						// }}
					>
						<Typography variant="body2" sx={optionTextSx ?? {}}>
							{option}
						</Typography>
					</Box>
				);
			})}
		</Box>
	);
}

export default LiquidityOptions;
