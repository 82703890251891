import { Box, Grid, Typography } from '@mui/material';
import { useState, useMemo, useEffect } from 'react';
import { useTheme } from '@emotion/react';
import { useLocation } from 'react-router-dom';

// Our Components
import Alert from 'components/Alert';
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import LiquidityOptions from 'components/Analytics/Liquidity/LiquidityOptions';
import LiquidityTable from 'components/Analytics/Liquidity/LiquidityTable';
import LoadingSkeleton from 'components/Frame/LoadingSkelton';
import Loader from 'components/Loader';
import { TertiaryButton } from 'components/Button/Button';
import ProjectionEndYearDropdown from 'components/Dropdown/analytics/ProjectionEndYearDropdown';
import ResultChart from 'components/Analytics/Results/ResultChart';

// Our Hooks
import useQueryGetAssumptions from 'hooks/analytics/assumptions/useQueryGetAssumptions';
import useMutateSaveAssumptions from 'hooks/analytics/assumptions/useMutateSaveAssumptions';
import useMutateGetLiquidAnalysis from 'hooks/analytics/calculations/useMutateGetLiquidAnalysis';

// Our Utils
import { isFormValueReady } from 'shared/utils';

// Local Constants
const LIQUIDITY_TYPE_MAPPING = {
	'Sell Assets': 'asset',
	'Interest Only Liabilities': 'interest_only_liability',
	'Amortized Liabilities': 'liability'
};

const LIQUIDITY_PILL_OPTIONS_DEFAULT_STATE = [
	{
		type: 'Sell Assets',
		interestRate: '',
		loanTerm: ''
	},
	{
		type: 'Interest Only Liabilities',
		interestRate: '',
		loanTerm: ''
	},
	{
		type: 'Amortized Liabilities',
		interestRate: '',
		loanTerm: ''
	}
];

// helper fns
const constructLiquidityAnalysisPayload = (
	assumptions,
	selectedLiquidityOptions,
	formattedProjectionEndYear,
	amountNeeded,
	discountRate
) => {
	const liquidityAnalysisPayload = {
		cash_required: +amountNeeded,
		risk_free_return_rate: +discountRate
	};

	selectedLiquidityOptions.forEach((option) => {
		const { type, interestRate, loanTerm } = option;
		const liquidityType = LIQUIDITY_TYPE_MAPPING[type];

		const isAsset = liquidityType === 'asset';

		if (isAsset) {
			const { assets, dividendReturnRate, dividendTax, capitalGainsTax } =
				assumptions;

			const investmentAccount = assets.find(
				(asset) => asset.type === 'Investment accounts'
			) ?? { presentValue: '0', firstGrowth: '6' };

			const { presentValue, firstGrowth } = investmentAccount;

			liquidityAnalysisPayload[liquidityType] = {
				asset_starting_value: +presentValue,
				annual_growth_interest_rate: +firstGrowth,
				dividend_yearly_return_rate: +dividendReturnRate,
				dividend_tax_rate: +dividendTax,
				cap_gains_tax_rate: +capitalGainsTax
			};

			return;
		}
		liquidityAnalysisPayload[liquidityType] = {
			liability_interest_rate: +interestRate,
			loan_terms_years: +loanTerm,
			horizon_years: formattedProjectionEndYear
		};
	});

	return liquidityAnalysisPayload;
};

function LiquidityAnalyzer() {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;
	const { state } = useLocation();

	// Mutations
	const mutateLiquidityAnalysis = useMutateGetLiquidAnalysis();
	const mutateSaveAssumptions = useMutateSaveAssumptions();

	const {
		isLoading: isLiquidityAnalysisLoading,
		isSuccess: isLiquidityAnalysisSuccess,
		isError: isLiquidityAnalysisError,
		data: liquidityAnalysisData
	} = mutateLiquidityAnalysis;

	// State
	const [amountNeeded, setAmountNeeded] = useState('');
	const [selectedLiquidityOptions, setSelectedLiquidityOptions] = useState(
		LIQUIDITY_PILL_OPTIONS_DEFAULT_STATE
	);

	const [projectedYearsFromNow, setProjectedYearsFromNow] = useState('');

	// Form Value Readiness
	const isAmountNeededReady =
		isFormValueReady(amountNeeded) && amountNeeded > 0;

	const isSelectedLiquidityOptionsReady =
		selectedLiquidityOptions.length > 0 &&
		selectedLiquidityOptions.every(
			({ interestRate, loanTerm }) =>
				isFormValueReady(interestRate) &&
				isFormValueReady(loanTerm) &&
				+interestRate > 0 &&
				+loanTerm > 0
		);

	const isProjectionYearsFromNowReady = isFormValueReady(
		projectedYearsFromNow
	);

	const isLiquidityAnalyzerReady =
		isAmountNeededReady &&
		isSelectedLiquidityOptionsReady &&
		isProjectionYearsFromNowReady;

	const prePopulatedClientData = state?.clientSelected;

	const {
		isLoading,
		isSuccess,
		data: assumptions
	} = useQueryGetAssumptions(prePopulatedClientData?.id);

	useEffect(() => {
		mutateLiquidityAnalysis.reset();
		setAmountNeeded('');
		setProjectedYearsFromNow('');
		setSelectedLiquidityOptions(LIQUIDITY_PILL_OPTIONS_DEFAULT_STATE);
	}, [prePopulatedClientData?.id]);

	useEffect(() => {
		if (isSuccess) {
			const {
				amountNeeded: existingAmount,
				projectedYearsFromNow: existingProjectedYearsFromNow,
				liquidityOptions: existingLiquidityOptions
			} = assumptions;

			if (existingAmount) {
				setAmountNeeded(existingAmount);
			}

			if (existingProjectedYearsFromNow) {
				const formattedProjectedYearsFromNow = `${existingProjectedYearsFromNow} years from now`;

				setProjectedYearsFromNow(formattedProjectedYearsFromNow);
			}

			if (existingLiquidityOptions) {
				setSelectedLiquidityOptions(existingLiquidityOptions);
			}
		}
	}, [assumptions, isSuccess]);

	const submitLiquidityAnalysis = () => {
		// Extract num from string and convert to number
		const formattedProjectionEndYear = Number.parseInt(
			projectedYearsFromNow,
			10
		);

		const saveAnalysisPayload = {
			...assumptions,
			amountNeeded: +amountNeeded,
			projectedYearsFromNow: formattedProjectionEndYear,
			liquidityOptions: selectedLiquidityOptions
		};

		const { discountRate } = assumptions;

		// TODO: Add edge case if investment account is not found

		const liquidityAnalysisPayload = constructLiquidityAnalysisPayload(
			assumptions,
			selectedLiquidityOptions,
			formattedProjectionEndYear,
			amountNeeded,
			discountRate
		);

		mutateSaveAssumptions.mutate(saveAnalysisPayload);
		mutateLiquidityAnalysis.mutate(liquidityAnalysisPayload);
	};

	const formattedChartData = useMemo(() => {
		if (!isLiquidityAnalysisSuccess) return [];

		// will have data to work with

		// eslint-disable-next-line camelcase
		const { assets, liability_data, opportunity_cost_data } =
			liquidityAnalysisData;

		const chartData = [
			{
				name: 'Assets',
				value: assets.change_in_net_worth,
				fill: '#2985C2'
			},
			{
				name: 'Amortized Liabilities',
				// eslint-disable-next-line camelcase
				value: liability_data.change_in_net_worth,
				fill: '#FFBB00'
			},
			{
				name: 'Interest Only Liabilities',
				// eslint-disable-next-line camelcase
				value: opportunity_cost_data.change_in_net_worth,
				fill: '#44AD95'
			}
		].map((item) => ({
			...item,
			lender: ''
		}));

		return chartData;
	}, [liquidityAnalysisData, isLiquidityAnalysisSuccess]);

	if (isLoading) {
		return <LoadingSkeleton />;
	}

	if (isSuccess) {
		return (
			<Box
				sx={{
					width: '100%',
					height: '100%',
					backgroundColor: 'white',
					padding: 7,
					borderRadius: 3
				}}
			>
				{isLiquidityAnalysisError && <Alert variant="error" />}

				<Typography variant="h2Gascogne" sx={{ color: primary.indigo }}>
					Liquidity Analysis
				</Typography>

				<Typography variant="body2">
					Discover the cheapest way for your client to access
					liquidity today
				</Typography>

				<Grid container sx={{ marginTop: 3, marginBottom: 3 }}>
					<Grid item xs={12} sm={4}>
						<CurrencyTextInput
							variant="h3Gascogne"
							label="Amount Needed"
							value={amountNeeded}
							error={!isAmountNeededReady}
							helperText="Amount available to invest must be greater than $0"
							onChange={setAmountNeeded}
							labelSx={{
								display: 'block',
								marginBottom: 1.5
							}}
						/>
					</Grid>
				</Grid>

				<Typography variant="h3Gascogne">Liquidity Options</Typography>

				<Typography variant="body2" sx={{ marginBottom: 2 }}>
					Select as many as you&apos;d like
				</Typography>

				<LiquidityOptions
					selectedLiquidityOptions={selectedLiquidityOptions}
					// setSelectedLiquidityOptions={setSelectedLiquidityOptions}
				/>

				<Box sx={{ marginTop: 3, maxWidth: 800 }}>
					<Typography
						variant="h3Gascogne"
						sx={{ display: 'block', marginBottom: 1 }}
					>
						Details
					</Typography>
					<LiquidityTable
						rowsState={selectedLiquidityOptions}
						setRowsState={setSelectedLiquidityOptions}
					/>
				</Box>

				<ProjectionEndYearDropdown
					projectedYearsFromNow={projectedYearsFromNow}
					setProjectedYearsFromNow={setProjectedYearsFromNow}
					labelVariant="h3Gascogne"
					labelSx={{ display: 'block', marginBottom: 1.5 }}
					sx={{
						marginTop: 3,
						width: 266.5
					}}
				/>

				{isLiquidityAnalysisLoading && (
					<Loader boxSX={{ marginTop: 3 }} />
				)}

				{!isLiquidityAnalysisLoading && (
					<TertiaryButton
						sx={{ marginTop: 3 }}
						disabled={!isLiquidityAnalyzerReady}
						onClick={submitLiquidityAnalysis}
					>
						Compare Options
					</TertiaryButton>
				)}

				{isLiquidityAnalysisSuccess && (
					<ResultChart
						subtitleText="Change in Future Net Worth ($K)"
						withTooltip
						tooltipText="This plot shows the impact of using different sources of liquidity today on your future net worth"
						caption={null}
						recommendation={
							liquidityAnalysisData.response_text1 ??
							'something went wrong.'
						}
						isSwitchable={false}
						isFutureMode={false}
						setIsFutureMode={() => {}}
						currentBarchartData={formattedChartData}
						reset={() => mutateLiquidityAnalysis.reset()}
					/>
				)}
			</Box>
		);
	}
}

export default LiquidityAnalyzer;
